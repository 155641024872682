<template>
  <div style="height: calc(100vh - 40px); overflow-y: auto">
    <el-card
      shadow="never"
      style="
        overflow-y: scroll;
        height: calc(100% - 80px);
        width: calc(100% - 280px);
      "
    >
      <div class="flex" style="align-items: center">
        <span>商户数据</span>
        <div class="flex_1"></div>
        <el-button type="primary" size="mini" @click="openAddModal"
          >添加</el-button>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        >
        </text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
          :data="tableData"
          style="overflow-y: scroll; height: calc(100% - 80px);width: 100%"
        >

          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">

              <!-- <template v-if="item.label === '状态'">
                <el-popconfirm
                  @onConfirm="confirmStatus(scope.row)"
                  @confirm="confirmStatus(scope.row)"
                  :title="`确定要${
                    statusOptions[scope.row.status == '正常' ? '禁用' : '正常']
                  }该数据吗？`"
                >
                  <el-button type="text" slot="reference">
                    {{ statusOptions[scope.row.status] }}
                  </el-button>
                </el-popconfirm>
              </template> -->
              <!-- <template v-else-if="item.label === '操作'"> -->
              <template v-if="item.label === '操作'">

                <el-button
                  style="margin-left: 8px"
                  type="text"
                  ref="openDetail"
                  @click="openDetailTeacher(scope.row)"
                  >查看教师</el-button
                >
                <!-- <el-button
                  style="margin-left: 8px"
                  type="text"
                  @click="openEditModal(scope.row)"
                  >编辑</el-button
                > -->
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
    <AddModal
      ref="add"
      title="添加商户"
      type="primary"
      @submit="addMember"
    ></AddModal>
  </div>
</template>

<script>
import TextButton from "@/components/TextButton";
import AddModal from "@/views/member/merchantdata"
// 默认展示列
const baseColSetting = [

  {
    label: "商户名称",
    prop: "merchantName",
    state: true,
    align: "left",
    width: "50px",
  },
  {
    label: "状态",
    prop: "state",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "创建时间",
    prop: "createTime",
    state: true,
    sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "150",
  },
]
export default {
  components: { TextButton,AddModal },
  data() {
    return {
      tableKey: 1,
      title: "",
      size: "small",
      tableData:[
        {
          merchantName:'王静',
          state:"启用",
          createTime:"2022-02-20",
          state: true,

        }
      ],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      searchForm: {
        loginName: null,
        name: null,
        telNumber: null,
        merchantStatus: null,
        merchantName: null,
      },
      colSetting:JSON.parse(JSON.stringify(baseColSetting)), //表格展示列
      baseColSetting:JSON.parse(JSON.stringify(baseColSetting))
    };
  },
  created() {
    // this.getList();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    /**
     * 获取列表
     */

    // async getListHandler() {
    //   let responseData = await getMerchantList(
    //     {
    //     pageNum: this.pageInfo.pageNum,
    //     pageSize: this.pageInfo.pageSize,
    //     needCount: this.pageInfo.needCount,
    //     ...this.searchForm,
    //     }
    //   );
    //   console.log(responseData)
    //   if (responseData && responseData.state == "success") {
    //     this.tableData = responseData.body.list;
    //     this.pageInfo.total = responseData.body.total;
    //   }
    // },
    // 刷新
    search() {
      this.pageInfo.pageNum = 1;
      // 刷新表格
      // this.getList()
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    // 添加商户
    openAddModal(){
      this.$refs.add.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.add.initData(); //清空表单
      });
    },
    // 查看老师(可以是弹窗)
    openDetailTeacher(item){
      this.$router.push({path:'/prepareLesson/teacherDetails',query:{merchantId:item.id}})


      // this.$router.push({path:'/merchantAccount/teacher',query:{merchantId:item.id}})
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const { id, state } = item;
      forbiddenMerchant({
        id,
        merchantStatus: state == "正常" ? "禁用" : '正常',
      }).then(() => {
        this.getListHandler();
      });
    },
    /**
     * 重置表单
     */
    // resetForm() {
    //   this.pageInfo.pageNum = 1;
    //   this.pageInfo.pageSize = 10;
    //   this.searchForm = {
    //     loginName: null,
    //     name: null,
    //     telNumber: null,
    //     merchantStatus: null,
    //     merchantName: null,
    //   };
    //   this.getListHandler();
    // },
    // 点击确定
    addMember(){

    }
  },
};
</script>

<style>
</style>
